@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    --color-black: #000000;
    --color-white: #ffffff;
    --rgb-indigo: 79 70 229;
    --rgb-gray: 156 163 175;
    --rgb-flag-blue: 56 102 155;
    --rgb-dark-blue: 10 49 97;
    --rgb-light-gray: 226 226 226;
    --rgb-dark-light-gray: 216 216 216;
    /* Someone please come up with a better name for this */
    --color-primary: var(--rgb-indigo);
    --color-primary-dark: var(--rgb-indigo);
    --color-secondary: var(--rgb-gray);
    --color-secondary-dark: var(--rgb-gray);
    --color-tertiary: var(--rgb-light-gray);
    --color-tertiary-dark: var(--rgb-dark-light-gray);
    font-variation-settings: "slnt" var(--slnt-axis, 0);

    /* css street signs */
    --sign-yellow: #f7b500;
    --sign-size: 210px;
    --sign-font-size: 50px;

}