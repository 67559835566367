.ag-theme-quartz-dark {
    --ag-background-color: transparent;

    --ag-header-foreground-color: theme('colors.white');
    --ag-header-background-color: transparent;
    --ag-header-height: 48px;
    --ag-borders: none;

    --ag-border-radius: theme('borderRadius.lg');
    --ag-row-hover-color: transparent;

    --ag-grid-size: 9px;
    --ag-list-item-height: 58px;

    --ag-header-column-resize-handle-color: theme('colors.gray.600');

    .ag-root-wrapper {
        border-radius: 0;
    }

    .ag-header-container {
        background-color: theme('colors.gray.800/50%');
        border-radius: theme('borderRadius.lg');
    }

    .ag-header {
        margin-bottom: theme('spacing.2');

        .ag-header-cell-text {
            text-transform: uppercase;
            font-weight: theme('fontWeight.bold');
            font-size: theme('fontSize.xs');
            color: theme('colors.gray.400');
        }

        .ag-header-row {
            height: 58px;
        }
    }

    .ag-header-cell-resize {
        right: 4px;
    }

    .ag-row {
        border-radius: theme('borderRadius.lg');
        border-color: transparent;
        border-top-width: 5px;
        border-bottom-width: 5px;
        height: 58px;
        cursor: pointer;
        color: theme('colors.gray.400');
    }

    .ag-row-selected {
        color: theme('colors.white');
    }

    .ag-row-selected:before {
        border-radius: theme('borderRadius.lg');
        background-color: theme('colors.indigo.600');
    }

    .ag-row-hover:not(.ag-row-selected):before {
        border-radius: theme('borderRadius.lg');
        background-color: theme('colors.gray.800');
    }

    .ag-row-hover.ag-row-selected:before {
        border-radius: theme('borderRadius.lg');
        background-color: theme('colors.indigo.700');
    }

    .ag-cell-focus:focus-within,
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
        border-color: theme('colors.white');
        border-radius: theme('borderRadius.lg');
        box-shadow: 0 0 0 1px theme('colors.white');
    }

    .ag-checkbox-input-wrapper {
        background-color: theme('colors.gray.900');
        border-radius: theme('borderRadius.md');
    }

    .ag-checkbox-input-wrapper::after {
        color: theme('colors.gray.600');
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: theme('colors.white');
    }
}